import classNames from "classnames";
import { useEffect, useState } from "react";
export const Popup = ({ isVisible, close, containerCls, initCls, endCls, children }) => {
    const [up, setup] = useState(false);
    useEffect(() => {
        if (isVisible) {
            if (document.body.clientWidth < 769) {
                document.body.style.overflow = "hidden";
            }
            setTimeout(() => {
                setup(true);
            }, 100);
            return () => {
                if (document.body.clientWidth < 769) {
                    document.body.style.overflow = "unset";
                }
            };
        }
    }, [isVisible]);
    return (<div className={classNames("fixed inset-0 z-50 bg-black/30 lg:absolute", isVisible ? "block" : "hidden")} onClick={() => close()}>
            <div className={classNames("absolute transition-all duration-500 lg:relative", containerCls, up ? initCls : endCls)} onClick={(event) => event.stopPropagation()}>
                {children}
            </div>
        </div>);
};
