import { useSimplePdp } from "@/lib/preFetch/preFetchPdp";
import { usePromotionTag } from "@/lib/preFetch/prefetchPromoTag";
import { useWishlist } from "@/lib/store/wishlist";
import { useStores } from "@/stores";
import { Arrow, FasletWidgetInWishlist, Link, NextImage, ProductStatus, PromotionalImage, SizePopup, Trash, transMoney, useI18n, usePending, useWishlistItem, } from "@jog/react-components";
import { gtmRemoveFromWishlist } from "@jog/shared";
import classNames from "classnames";
const Discount = ({ firstPrice, isDiscount }) => {
    const { $t } = useI18n();
    if (!isDiscount)
        return <></>;
    return (<div className="mt-1 text-[10px] text-grey-dark">
            <p>{$t(`Original price: {price}`, { price: transMoney(firstPrice, false) })}</p>
        </div>);
};
const Price = ({ isDiscount, price }) => (<div className={classNames(isDiscount ? "text-red-main" : "text-black", "mt-1 font-primary text-[13px] font-medium leading-4")}>
        {transMoney(price)}
    </div>);
const WishlistItem = ({ pdpData }) => {
    const { faslet } = useSimplePdp();
    const { $t } = useI18n();
    const promotionTag = usePromotionTag(pdpData.discountName);
    const removeProductFromWishlist = useWishlist.use.removeWishlistItem();
    const { cartStore } = useStores();
    const { name, size, popupShow, sku, productStatus, hasDiscount, addProductToCart, setPopupShow, setSku } = useWishlistItem(pdpData, cartStore, removeProductFromWishlist);
    const { setPending } = usePending();
    return (<div className="flex w-full flex-col bg-white">
            <Link className={classNames("flex flex-1 flex-col", {
            "cursor-not-allowed": productStatus === ProductStatus.Unavailable,
        })} href={pdpData.pageUrl} onClick={(e) => {
            if (productStatus === ProductStatus.Unavailable) {
                e.preventDefault();
                e.stopPropagation();
            }
        }}>
                <div className="relative">
                    <div className="relative">
                        <div className={classNames(pdpData.image && (!pdpData.purchasable || !pdpData.published)
            ? "opacity-30"
            : "opacity-100")}>
                            <NextImage alt={name} height={258} width={170} className="w-full" src={pdpData.image || "/images/placeholderImage.jpg"}/>
                        </div>

                        <PromotionalImage className="absolute top-2.5 right-0 w-17.5 object-cover" promotionTag={promotionTag}/>
                    </div>
                    <div className="absolute right-2 bottom-2 cursor-pointer" onClick={async (e) => {
            e.preventDefault();
            await removeProductFromWishlist(pdpData.id);
            gtmRemoveFromWishlist([
                {
                    id: pdpData.gtmId,
                    name: pdpData.productName[process.env.NEXT_PUBLIC_LOCALE] || pdpData.productName.en,
                    price: +(pdpData.discountedPrice || pdpData.originalPrice),
                },
            ]);
        }}>
                        <Trash className="block lg:hidden"/>
                        <Trash width={30} height={30} className="hidden lg:block"/>
                    </div>
                </div>

                <div className="mt-2 flex-1 text-11 font-bold capitalize lg:text-xs">{name}</div>
            </Link>
            <div className="mt-2 flex flex-col gap-2">
                <div className="flex flex-1 flex-col">
                    {productStatus === ProductStatus.Unavailable && !pdpData.originalPrice ? (<span className="text-15 font-medium">{$t("Item not available")}</span>) : (<>
                            <Price isDiscount={hasDiscount} price={pdpData.discountedPrice}/>
                            <Discount isDiscount={hasDiscount} firstPrice={pdpData.originalPrice}/>
                        </>)}
                </div>
                <div className="relative">
                    <div className={classNames("flex items-center justify-between border py-2.5 px-2 text-11 lg:text-xs", !pdpData.purchasable || !pdpData.published
            ? "cursor-default text-gray-disabledText"
            : "cursor-pointer", !pdpData.published ? "border-gray-disabled bg-gray-disabled" : "border-black")} onClick={(e) => {
            e.preventDefault();
            if (productStatus === ProductStatus.Available) {
                e.currentTarget.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "nearest",
                });
                setPopupShow(true);
            }
        }}>
                        <span>
                            {productStatus === ProductStatus.SoldOut
            ? $t("Out of stock")
            : productStatus === ProductStatus.Unavailable
                ? $t("Item not available")
                : size || $t("Select your size")}
                        </span>
                        <Arrow direction="down"/>
                    </div>
                    {popupShow && (<SizePopup sizes={pdpData.sizes} setSku={setSku} sku={sku} setPopupShow={setPopupShow} popupShow={popupShow} FasletBtn={faslet ? (<FasletWidgetInWishlist pdpData={pdpData} addToCart={addProductToCart} className="z-1 lg:-top-18 absolute -top-[70px] left-4 lg:left-0"/>) : null}/>)}
                </div>
                <div onClick={() => {
            if (productStatus === ProductStatus.Unavailable) {
                return removeProductFromWishlist(pdpData.id);
            }
            if (!sku)
                return;
            setPending(true);
            addProductToCart().finally(() => setPending(false));
        }} className={classNames("bg-black py-2.5 px-2 text-center text-11 lg:text-xs", !pdpData.purchasable || (pdpData.published && pdpData.purchasable && !sku)
            ? "cursor-default text-gray-disabledText"
            : "cursor-pointer text-white")}>
                    {productStatus === ProductStatus.SoldOut
            ? $t("Size sold out")
            : productStatus === ProductStatus.Unavailable
                ? $t("Remove item")
                : $t("In shopping basket")}
                </div>
                <div onClick={() => setPopupShow(false)} className={classNames("fixed inset-0 z-[5] hidden bg-black/30 lg:bg-transparent", popupShow ? "lg:block" : "lg:hidden")}></div>
            </div>
        </div>);
};
export default WishlistItem;
