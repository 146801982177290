import { gtmAddToCartFromWishlist } from "@jog/shared";
import { useSession } from "next-auth/react";
import { useCallback, useMemo, useState } from "react";
import { useAddedProduct } from "./useAddedProduct";
export var ProductStatus;
(function (ProductStatus) {
    ProductStatus[ProductStatus["SoldOut"] = 0] = "SoldOut";
    ProductStatus[ProductStatus["Unavailable"] = 1] = "Unavailable";
    ProductStatus[ProductStatus["Available"] = 2] = "Available";
})(ProductStatus || (ProductStatus = {}));
export const useWishlistItem = (pdpData, cartStore, removeProductFromWishlist) => {
    const name = useMemo(() => {
        const rawName = pdpData.productName[process.env.NEXT_PUBLIC_LOCALE] || pdpData.productName.en;
        return rawName.slice(0, 1).toUpperCase() + rawName.slice(1);
    }, [pdpData.productName]);
    const [popupShow, setPopupShow] = useState(false);
    const [sku, setSku] = useState("");
    const productStatus = useMemo(() => {
        if (pdpData.published && !pdpData.purchasable && pdpData.sizes)
            return ProductStatus.SoldOut;
        if ((!pdpData.purchasable && !pdpData.sizes) || !pdpData.published)
            return ProductStatus.Unavailable;
        return ProductStatus.Available;
    }, [pdpData]);
    const hasDiscount = pdpData.discountedPrice && pdpData.discountedPrice < ((pdpData === null || pdpData === void 0 ? void 0 : pdpData.originalPrice) || 0);
    const session = useSession();
    const isLoggedIn = useMemo(() => session.status === "authenticated", [session.status]);
    const { addedProduct } = useAddedProduct();
    const addProductToCart = useCallback(async () => {
        if (!cartStore.isCtaDisabled) {
            await cartStore.addProductToCart(isLoggedIn, sku);
            // Google Tag Manager code
            gtmAddToCartFromWishlist([
                {
                    id: pdpData.productId,
                    name: pdpData.productName[process.env.NEXT_PUBLIC_LOCALE] || pdpData.productName.en,
                    price: pdpData.discountedPrice || pdpData.originalPrice || 0,
                },
            ]);
            setPopupShow(false);
            addedProduct(sku);
            // remove from wishlist
            await removeProductFromWishlist(pdpData.id);
        }
    }, [addedProduct, cartStore, isLoggedIn, pdpData, removeProductFromWishlist, sku]);
    const size = useMemo(() => {
        var _a, _b;
        switch ((_a = pdpData.sizes) === null || _a === void 0 ? void 0 : _a.type) {
            case "size":
                return (_b = pdpData.sizes.sizes.find((i) => i.sku === sku)) === null || _b === void 0 ? void 0 : _b.size;
            case "waist_length": {
                const item = pdpData.sizes.sizes.find((i) => i.sku === sku);
                if (!item)
                    return null;
                return `${item.waist}/${item.length}`;
            }
            default: {
                return null;
            }
        }
    }, [pdpData, sku]);
    return {
        name,
        popupShow,
        sku,
        productStatus,
        hasDiscount,
        addProductToCart,
        setPopupShow,
        setSku,
        size,
    };
};
