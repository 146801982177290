import classNames from "classnames";
import { Close, Popup } from "../../components";
import { useI18n } from "../../hooks";
const Sizes = ({ sizes, setSku, sku: selectedSku, setPopupShow, }) => {
    const { $t } = useI18n();
    switch (sizes.type) {
        case "size":
            return (<>
                    {sizes.sizes.map(({ sku, isOnStock, size }) => (<div key={sku} onClick={() => {
                        if (isOnStock && sku) {
                            setSku(sku);
                            setPopupShow(false);
                        }
                    }} className={classNames("flex cursor-pointer justify-between py-3 px-8", isOnStock ? "text-black" : "text-gray-disabled", selectedSku === sku ? "bg-gray-disabled" : "bg-white")}>
                            <span>{size}</span>
                            {!isOnStock && <span>{$t("Out of stock")}</span>}
                        </div>))}
                </>);
        case "waist_length":
            return (<>
                    {sizes.sizes.map(({ sku, isOnStock, length, waist }) => (<div key={sku} onClick={() => {
                        if (isOnStock && sku) {
                            setSku(sku);
                            setPopupShow(false);
                        }
                    }} className={classNames("flex cursor-pointer justify-between py-3 px-8", isOnStock ? "text-black" : "text-gray-disabled", selectedSku === sku ? "bg-gray-disabled" : "bg-white")}>
                            <span>{`${waist}/${length}`}</span>
                            {!isOnStock && <span>{$t("Out of stock")}</span>}
                        </div>))}
                </>);
        default: {
            return <></>;
        }
    }
};
export const SizePopup = ({ sizes, setSku, popupShow, setPopupShow, FasletBtn, sku, }) => {
    const { $t } = useI18n();
    return (<Popup isVisible={popupShow} containerCls="right-0 left-0 h-1/2 lg:w-full lg:!bottom-0 lg:max-h-[400px] overflow-y-auto lg:h-auto lg:mx-auto" initCls="bottom-0 opacity-100 lg:bottom-[40%]" endCls="-bottom-[40%] opacity-0 lg:bottom-[40%] lg:opacity-100" close={() => {
            setPopupShow(false);
            setSku("");
        }}>
            {FasletBtn}
            <div className="relative flex h-full flex-col overflow-auto border-t border-t-gray-disabled bg-white lg:border lg:border-gray-disabled">
                <div className="sticky top-0 bg-white py-5 px-8 text-sm font-medium">
                    <div className="flex justify-between">
                        <span>{$t("Select your size")}</span>
                        <span className="cursor-pointer lg:hidden" onClick={() => {
            setPopupShow(false);
            setSku("");
        }}>
                            <Close width={24} height={24} className="scale-75"/>
                        </span>
                    </div>
                </div>
                <div className="flex flex-col pb-4">
                    <Sizes sizes={sizes} sku={sku} setSku={setSku} setPopupShow={setPopupShow}/>
                </div>
            </div>
        </Popup>);
};
