import { useI18n } from "../../hooks";
import { cn } from "../../ui";
export const ReCaptchaDisclaimer = ({ className }) => {
    const { $t } = useI18n();
    return (<div className={cn("mt-2.5", className)}>
            <p className="mb-0.5 text-xs">{$t("This site is protected by reCAPTCHA.")}</p>
            <p className="mb-0.5 text-xs">
                Google{" "}
                <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
                    {$t("Privacy Policy")}
                </a>{" "}
                {$t("and")}{" "}
                <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
                    {$t("Terms of Service")}
                </a>
                .
            </p>
        </div>);
};
