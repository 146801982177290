import { lowerCaseEq } from "@jog/shared";
export const getStoreURLById = (storeId) => {
    switch (storeId) {
        case "705":
            return {
                url: "https://www.wearegarcia.de/",
                name: "wearegarcia.de",
            };
        case "706":
            return {
                url: "https://www.wearegarcia.nl/",
                name: "wearegarcia.nl",
            };
        case "707":
            return {
                url: "https://www.wearegarcia.be/",
                name: "wearegarcia.be",
            };
        default:
            return {
                url: "https://www.wearegarcia.com/",
                name: "wearegarcia.com",
            };
    }
};
export const WrongCountryErrorMessage = ({ country }) => {
    const className = "text-red-main font-bold decoration-0 hover:underline";
    switch (country) {
        case "DE":
            return (<p>
                    Von Deutschland aus bestellen?{" "}
                    <a className={className} href="https://www.wearegarcia.de">
                        Klicke hier
                    </a>
                    , um zum deutschen Webshop zu gelangen.
                </p>);
        case "NL":
            return (<p>
                    Bestellen vanuit Nederland?{" "}
                    <a className={className} href="https://www.wearegarcia.nl">
                        Klik hier
                    </a>{" "}
                    om naar de Nederlandse webshop te gaan.
                </p>);
        case "BE":
            return (<p>
                    Bestellen vanuit Belgie?{" "}
                    <a className={className} href="https://www.wearegarcia.be">
                        Klik hier
                    </a>{" "}
                    om naar de Belgische webshop te gaan.
                </p>);
        default:
            return (<p>
                    Please visit our{" "}
                    <a className={className} href="https://www.wearegarcia.com">
                        wearegarcia.com
                    </a>{" "}
                    webshop.
                </p>);
    }
};
export const checkCountry = (country) => {
    const site = process.env.NEXT_PUBLIC_COUNTRY.toUpperCase();
    switch (site) {
        case "NL":
        case "BE":
        case "DE": {
            return lowerCaseEq(site, country);
        }
        default: {
            return true;
        }
    }
};
