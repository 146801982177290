import { FasletWidget as FasletWidgetFC } from "@faslet/react-widget";
import { getGtmIdFromCT } from "@jog/shared";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ClientOnly } from "../../ClientOnly";
import { useI18n } from "../../hooks";
import { Ruler } from "../Icon/Ruler";
function transSizeToFasletVariants(size, price) {
    return {
        variantId: size.sku,
        inStock: size.isOnStock,
        sku: size.sku,
        colorId: ``,
        price,
    };
}
export const FasletWidgetInWishlist = ({ pdpData, addToCart, className }) => {
    // Get the product variants and colors
    const { $t } = useI18n();
    const [fasletAvailable, setFasletAvailable] = useState(false);
    const [btnLabel, setBtnLabel] = useState("");
    const shopId = process.env.NEXT_PUBLIC_FASLET_SHOP_ID;
    const productIdForFaslet = useMemo(() => getGtmIdFromCT(pdpData.attributes), [pdpData]);
    useEffect(() => {
        setFasletAvailable(false);
    }, [pdpData.id]);
    const variants = useMemo(() => {
        var _a;
        const price = pdpData.discountedPrice || pdpData.originalPrice || 0;
        switch ((_a = pdpData.sizes) === null || _a === void 0 ? void 0 : _a.type) {
            case "size": {
                return pdpData.sizes.sizes.map((i) => ({
                    ...transSizeToFasletVariants(i, price),
                    sizeLabel: i.size,
                }));
            }
            case "waist_length": {
                return pdpData.sizes.sizes.map((i) => ({
                    ...transSizeToFasletVariants(i, price),
                    sizeLabel: `${i.waist}/${i.length}`,
                }));
            }
            default: {
                return [];
            }
        }
    }, [pdpData]);
    const addProductToCart = useCallback(async (id) => {
        const product = variants.find((variant) => variant.variantId === id);
        const productSku = (product === null || product === void 0 ? void 0 : product.sku) || "";
        await addToCart(productSku);
    }, [addToCart, variants]);
    useEffect(() => {
        window._faslet = {
            onButtonShow: () => setFasletAvailable(true),
            onButtonHidden: () => setFasletAvailable(false),
        };
    }, [setFasletAvailable]);
    // Render the widget
    return (<ClientOnly>
            {shopId && productIdForFaslet ? (<div id="faslet-button" className="faslet-container">
                    <FasletWidgetFC productId={productIdForFaslet} shopId={shopId} brandId={(pdpData === null || pdpData === void 0 ? void 0 : pdpData.brand) || ""} productImageUrl={(pdpData === null || pdpData === void 0 ? void 0 : pdpData.image) || ""} productName={pdpData.productName[process.env.NEXT_PUBLIC_LOCALE] || pdpData.productName.en} variants={variants} onAddToCart={addProductToCart} onResult={({ label }) => {
                setBtnLabel(label);
            }}/>
                    {/* custom button text */}
                    {fasletAvailable ? (<span className={classNames("mt-3.5 mb-1.5 inline-block cursor-pointer gap-1 border-none bg-white py-3 px-2 leading-none lg:rounded-sm lg:border lg:border-solid lg:border-black", className)} id="faslet-button-text" onClick={() => {
                    var _a, _b;
                    (_b = (_a = window === null || window === void 0 ? void 0 : window._faslet) === null || _a === void 0 ? void 0 : _a.openWidget) === null || _b === void 0 ? void 0 : _b.call(_a);
                }}>
                            {btnLabel ? (<>
                                    <span>{$t("Your size is")}</span>
                                    <span className="ml-2 rounded border border-gray-silver px-2 py-1">{btnLabel}</span>
                                </>) : (<span className="flex items-center">
                                    <Ruler />
                                    <span className="ml-2">{$t("Measure Modal Description")}</span>
                                </span>)}
                        </span>) : null}
                </div>) : null}
        </ClientOnly>);
};
