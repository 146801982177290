import { GRichText, Modal } from "@/components";
import { useWishlist } from "@/lib/store/wishlist";
import { useStores } from "@/stores";
import { WrongCountryErrorMessage, checkCountry } from "@/util/helperFunctions";
import { LoginFormButton, LoginFormField, LoginFormFieldError, LoginFormFieldInput, LoginFormFieldLabel, ReCaptchaDisclaimer, useCheckEmail, useI18n, useLogin, } from "@jog/react-components";
import { gtmAddUserId } from "@jog/shared";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import { useCallback, useState } from "react";
const BUTTON_CLASS = "border-none bg-black py-2.5 px-12.5 font-primary text-xs uppercase text-white hover:underline focus:outline-none disabled:pointer-events-none disabled:opacity-60";
const INPUT_CLASS = "w-full border border-gray-main p-3 text-left outline-none disabled:bg-gray-bg";
const Login = ({ email, onLogin }) => {
    const { $t } = useI18n();
    const formProps = useLogin(onLogin);
    return (<Formik initialValues={{ email, password: "" }} {...formProps}>
            {({ isValid, isSubmitting }) => (<Form className="space-y-7.5">
                    <LoginFormField>
                        <LoginFormFieldLabel name="email">{$t("E-mail address")}</LoginFormFieldLabel>
                        <LoginFormFieldInput>
                            <Field type="text" className={INPUT_CLASS} id="email" name="email" disabled/>
                        </LoginFormFieldInput>
                    </LoginFormField>

                    <LoginFormField>
                        <LoginFormFieldLabel name="password">{$t("Password")}</LoginFormFieldLabel>
                        <LoginFormFieldInput>
                            <Field className={INPUT_CLASS} type="password" id="password" name="password" disabled={isSubmitting}/>
                        </LoginFormFieldInput>
                        <LoginFormFieldError name="password"/>
                    </LoginFormField>
                    <LoginFormButton className={BUTTON_CLASS} disabled={!isValid || isSubmitting}>
                        {$t("next one")}
                    </LoginFormButton>
                </Form>)}
        </Formik>);
};
const CheckEmail = ({ setEmail }) => {
    const { $t } = useI18n();
    const formProps = useCheckEmail(setEmail, checkCountry);
    return (<Formik initialValues={{ email: "", country: "" }} {...formProps}>
            {({ isSubmitting, isValid }) => (<Form className="space-y-3.75">
                    <ErrorMessage render={(country) => (<div className="col-span-12 mt-1.25 text-13 text-red-main">
                                <WrongCountryErrorMessage country={country}/>
                            </div>)} name="country"/>
                    <LoginFormField>
                        <LoginFormFieldLabel name="email">{$t("E-mail address")}</LoginFormFieldLabel>
                        <LoginFormFieldInput>
                            <Field type="text" className={INPUT_CLASS} id="email" name="email" disabled={isSubmitting}/>
                        </LoginFormFieldInput>
                        <LoginFormFieldError name="email"/>
                    </LoginFormField>

                    <LoginFormButton className={BUTTON_CLASS} disabled={!isValid || isSubmitting}>
                        {$t("Log in / Register")}
                    </LoginFormButton>
                </Form>)}
        </Formik>);
};
const Content = observer(({ title, hideModal }) => {
    const [email, setEmail] = useState("");
    const mergeWishlist = useWishlist.use.mergeWishlist();
    const myWishlist = useWishlist.use.myWishlist();
    const { cartStore, userStore } = useStores();
    const updateUser = useCallback(async () => {
        var _a, _b, _c, _d, _e, _f;
        if (((_c = (_b = (_a = cartStore === null || cartStore === void 0 ? void 0 : cartStore.cartView) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.lineItems) === null || _c === void 0 ? void 0 : _c.length) && ((_e = (_d = cartStore === null || cartStore === void 0 ? void 0 : cartStore.cartView) === null || _d === void 0 ? void 0 : _d.body) === null || _e === void 0 ? void 0 : _e.id) !== userStore.userCartId) {
            const assignSuccess = await cartStore.assignCartFromAnonymousUser();
            if (assignSuccess) {
                await userStore.getUserData(false);
            }
            else {
                await userStore.getUserData(true);
                await cartStore.getCartView(cartStore.cartView.body.id);
            }
        }
        else {
            if (userStore.userCartId) {
                // user has previous cart, assign it
                await cartStore.getCartView(userStore.userCartId);
            }
        }
        if ((_f = userStore.userData) === null || _f === void 0 ? void 0 : _f.id) {
            localStorage.setItem("user-id", userStore.userData.id);
            gtmAddUserId(userStore.userData.id);
        }
    }, [cartStore, userStore]);
    const onLogin = useCallback(async () => {
        const wishlistId = localStorage.getItem("wishlistId");
        if (wishlistId) {
            await mergeWishlist();
        }
        else {
            await myWishlist();
        }
        await updateUser();
        hideModal();
        window.location.reload();
    }, [hideModal, mergeWishlist, myWishlist, updateUser]);
    return (<div className="-mx-5 -mt-5 p-5 text-left text-sm lg:py-10 lg:px-14">
            <div className="mb-12">
                <GRichText h1Class="text-2xl font-normal" h3Class="text-sm lg:text-base font-normal" paragraphClass="text-11 lg:text-sm" field={title}/>
            </div>
            {email ? <Login email={email} onLogin={onLogin}/> : <CheckEmail setEmail={setEmail}/>}
            <ReCaptchaDisclaimer className="text-black"/>
        </div>);
});
export const LoginModal = observer(({ hideModal, title }) => {
    return (<Modal hideModal={hideModal} modalClass="!w-full mb-0 lg:mb-auto !max-h-[none] !max-w-[700px] lg:!h-auto">
                <Content title={title} hideModal={hideModal}/>
            </Modal>);
});
